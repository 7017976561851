<template>

  <div class="grid">
    <div class="col-12 md:col-6">
      <h3><i class="pi pi-money-bill text-2xl"></i> Financeiro</h3>
    </div>
  </div>

  <Panel class="mt-2">

    <template #header>
      <div class="font-medium">
        Filtros
      </div>
    </template>

    <div class="grid p-fluid">

      <div class="p-field md:col-3">
        <label for="periodo">Período:</label>
        <Calendar v-model="periodo" selectionMode="range" dateFormat="dd/mm/yy"/>
      </div>

      <div class="p-field md:col-2">
        <Button
          id="filtrar"
          label="Filtrar"
          icon="pi pi-check"
          class="p-button-success mt-4"
          style="width: auto"
          @click="filtrar"
        ></Button>
      </div>

    </div>

  </Panel>

  <Panel class="mt-4">

    <template #header>
      <div class="font-medium">
        Agrupado por Empresa
      </div>
    </template>

    <div class="grid">
      <div class="col-12">

        <DataTable :value="dados_empresas"  :lazy="true" :loading="loading" responsiveLayout="scroll" v-model:expandedRows="empresasExpandidas">
          
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column class="col-empresa" field="nome" header="Empresa"></Column>
          <Column class="col-chamados" field="chamados" header="Chamados"></Column>
          <Column class="col-entregas" field="entregas" header="Entregas"></Column>
          <Column class="col-valor-total" field="valorTotal" header="Valor Total">
            <template #body="slotProps">
              {{ $utils.formatCurrency(slotProps.data.valorTotal) }}
            </template>
          </Column>
          <Column class="col-valor-entregadores" field="valorEntregadores" header="Valor Entregadores">
            <template #body="slotProps">
              {{ $utils.formatCurrency(slotProps.data.valorEntregadores) }}
            </template>          
          </Column>

          <template #expansion="{data}">
            <DataTable :value="data.entregadores" :paginator="false">
              <Column class="col-entregador" field="nome" header="Entregador"></Column>
              <Column class="col-entregas" field="entregas" header="Entregas"></Column>
              <Column class="col-entregas-km" header="Km Roteirizados">
                <template #body="slotProps">
                  {{ slotProps.data.roteirizacao.total/1000 }} <span v-show="slotProps.data.roteirizacao.qtdSemInformacao > 0">*</span>
                </template>
              </Column>
              <Column class="col-valor-total" field="valorTotal" header="Valor Total">
                <template #body="slotProps">
                  {{ $utils.formatCurrency(slotProps.data.valorTotal) }}
                </template>
              </Column>
            </DataTable>
          </template>

          <template #empty>
            Nenhum valor encontrado / processado.
          </template>

          <template #footer>

            <div class="totalizadores totalizadores_empresas">
              <span>Empresas: </span>
              <span>{{ totalizadores_empresas.empresas }}</span>
            </div>

            <div class="totalizadores totalizadores_empresas">
              <span>Chamados: </span>
              <span>{{ totalizadores_empresas.chamados }}</span>
            </div>

            <div class="totalizadores totalizadores_empresas">
              <span>Entregas: </span>
              <span>{{ totalizadores_empresas.entregas }}</span>
            </div>

            <div class="totalizadores totalizadores_empresas">
              <span>Total: </span>
              <span>{{ $utils.formatCurrency(totalizadores_empresas.total) }}</span>
            </div>

            <div class="totalizadores totalizadores_empresas">
              <span>Total Empresa: </span>
              <span>{{ $utils.formatCurrency(totalizadores_empresas.total_empresa) }}</span>
            </div>

            <div class="totalizadores totalizadores_empresas">
              <span>Total Entregadores: </span>
              <span>{{ $utils.formatCurrency(totalizadores_empresas.total_entregadores) }}</span>
            </div>

          </template>

        </DataTable>
        
      </div>
    </div>

  </Panel>

  <Panel class="mt-4">
    <template #header>
      <div class="font-medium">
        Agrupado por Entregador
      </div>
    </template>

    <div class="grid">
      <div class="col-12">
        <DataTable :value="dados_entregadores" :lazy="true" :loading="loading" responsiveLayout="scroll" v-model:expandedRows="entregadoresExpandidos">

          <Column :expander="true" headerStyle="width: 3rem" />
          <Column class="col-entregador" field="nome" header="Entregador"></Column>
          <Column class="col-entregas" field="entregas" header="Entregas"></Column>
          <Column class="col-entregas-1000" field="raio_1000" header="1km"></Column>
          <Column class="col-entregas-2000" field="raio_2000" header="2km"></Column>
          <Column class="col-entregas-3000" field="raio_3000" header="3km"></Column>
          <Column class="col-entregas-4000" field="raio_4000" header="4km"></Column>
          <Column class="col-entregas-5000" field="raio_5000" header="5km"></Column>
          <Column class="col-entregas-mais5000" field="raio_mais5000" header="+5km"></Column>
          <Column class="col-entregas-porcentagem" field="percent" header="%"></Column>
          <Column class="col-entregas-km" header="T.R.">
            <template #body="slotProps">
              {{ slotProps.data.roteirizacao.total/1000 }} <span v-show="slotProps.data.roteirizacao.qtdSemInformacao > 0">*</span>
            </template>
          </Column>
          <Column class="col-valor-total" field="valorTotal" header="Valor Total">
            <template #body="slotProps">
              {{ $utils.formatCurrency(slotProps.data.valorTotal) }}
            </template>
          </Column>

          <template #expansion="{data}">
            <DataTable :value="data.empresas" :paginator="false">
              <Column class="col-empresa" field="nome" header="Empresa"></Column>
              <Column class="col-entregas" field="entregas" header="Entregas"></Column>
              <Column class="col-entregas-1000" field="raio_1000" header="1km"></Column>
              <Column class="col-entregas-2000" field="raio_2000" header="2km"></Column>
              <Column class="col-entregas-3000" field="raio_3000" header="3km"></Column>
              <Column class="col-entregas-4000" field="raio_4000" header="4km"></Column>
              <Column class="col-entregas-5000" field="raio_5000" header="5km"></Column>
              <Column class="col-entregas-mais5000" field="raio_mais5000" header="+5km"></Column>
              <Column class="col-entregas-porcentagem" field="percent" header="%"></Column>
              <Column class="col-entregas-km" header="T.R.">
                <template #body="slotProps">
                  {{ slotProps.data.roteirizacao.total/1000 }} <span v-show="slotProps.data.roteirizacao.qtdSemInformacao > 0">*</span>
                </template>
              </Column>

            </DataTable>
          </template>          

          <template #empty>
            Nenhum valor encontrado / processado.
          </template>

          <template #footer>

            <div class="totalizadores">
              <span>Entregadores: </span>
              <span>{{ totalizadores_entregadores.entregadores }}</span>
            </div>

            <div class="totalizadores">
              <span>Até 1km: </span>
              <span v-if="totalizadores_entregadores.entregas > 0">{{ ((totalizadores_entregadores.totais["1000"]*100)/totalizadores_entregadores.entregas).toFixed(2) }}% ({{totalizadores_entregadores.totais["1000"]}})</span>
              <span v-else>0% (0)</span>
            </div>

            <div class="totalizadores">
              <span>Até 2km: </span>
              <span v-if="totalizadores_entregadores.entregas > 0">{{ ((totalizadores_entregadores.totais["2000"]*100)/totalizadores_entregadores.entregas).toFixed(2) }}% ({{totalizadores_entregadores.totais["2000"]}})</span>
              <span v-else>0% (0)</span>
            </div>

            <div class="totalizadores">
              <span>Até 3km: </span>
              <span v-if="totalizadores_entregadores.entregas > 0">{{ ((totalizadores_entregadores.totais["3000"]*100)/totalizadores_entregadores.entregas).toFixed(2) }}% ({{totalizadores_entregadores.totais["3000"]}})</span>
              <span v-else>0% (0)</span>
            </div>

            <div class="totalizadores">
              <span>Até 4km: </span>
              <span v-if="totalizadores_entregadores.entregas > 0">{{ ((totalizadores_entregadores.totais["4000"]*100)/totalizadores_entregadores.entregas).toFixed(2) }}% ({{totalizadores_entregadores.totais["4000"]}})</span>
              <span v-else>0% (0)</span>
            </div>

            <div class="totalizadores">
              <span>Até 5km: </span>
              <span v-if="totalizadores_entregadores.entregas > 0">{{ ((totalizadores_entregadores.totais["5000"]*100)/totalizadores_entregadores.entregas).toFixed(2) }}% ({{totalizadores_entregadores.totais["5000"]}})</span>
              <span v-else>0% (0)</span>
            </div>

            <div class="totalizadores">
              <span>Mais de 5km: </span>
              <span v-if="totalizadores_entregadores.entregas > 0">{{ ((totalizadores_entregadores.totais["+5000"]*100)/totalizadores_entregadores.entregas).toFixed(2) }}% ({{totalizadores_entregadores.totais["+5000"]}})</span>
              <span v-else>0% (0)</span>
            </div>

            <div class="totalizadores">
              <span>Total: </span>
              <span>{{ $utils.formatCurrency(totalizadores_entregadores.total) }}</span>
            </div>

          </template>

        </DataTable>
      </div>
    </div>
  </Panel>

  <Toast position="top-right" />

</template>

<script>

import moment from 'moment-timezone';

export default {
  data() {
    return {
      //Controle de Apresentação
      loading: false,
      empresasExpandidas: [],
      entregadoresExpandidos: [],

      // Campos do Formulário
      periodo: null,

      // Dados cadastrados
      dados_empresas: [],
      totalizadores_empresas: {
        empresas: 0,
        chamados: 0,
        entregas: 0,
        total: 0,
        total_empresa: 0,
        total_entregadores: 0
      },
      dados_entregadores: [],
      totalizadores_entregadores: {
        entregas: 0,
        entregadores: 0,
        total: 0,
        totais: {
          "1000" : 0,
          "2000" : 0,
          "3000" : 0,
          "4000" : 0,
          "5000" : 0,
          "+5000" : 0,
        }
      },
    };
  },

  watch: {

    dados_empresas: function(dados) {

      this.totalizadores_empresas = {
        empresas: 0,
        chamados: 0,
        entregas: 0,
        total: 0,
        total_empresa: 0,
        total_entregadores: 0
      };

      for(const d of dados) {

        this.totalizadores_empresas.total += d.valorTotal;
        this.totalizadores_empresas.total_entregadores += d.valorEntregadores;
        this.totalizadores_empresas.total_empresa += d.valorTotal - d.valorEntregadores;
        this.totalizadores_empresas.entregas += d.entregas;
        this.totalizadores_empresas.chamados += d.chamados;
        this.totalizadores_empresas.empresas++;

      }

    },

    dados_entregadores: function(dados) {

      this.totalizadores_entregadores = {
        entregas: 0,
        entregadores: 0,
        total: 0,
        totais: {
          "1000" : 0,
          "2000" : 0,
          "3000" : 0,
          "4000" : 0,
          "5000" : 0,
          "+5000" : 0,
        }
      };

      for(const d of dados) {
        this.totalizadores_entregadores.entregas += d.entregas;
        this.totalizadores_entregadores.total += d.valorTotal;
        this.totalizadores_entregadores.entregadores++;

        for(const [k, q] of Object.entries(d.raios)) {
          this.totalizadores_entregadores.totais[k] += q;

          d['raio_' + k.replace('+','mais')] = q;

        }

        for(const e of d.empresas) {

          for(const [kr, r] of Object.entries(e.raios)) {

            e['raio_' + kr.replace('+','mais')] = r;

          }

        }

      }

      for(const d of dados) {

        d.percent = ((d.entregas*100)/this.totalizadores_entregadores.entregas).toFixed(2);

        for(const e of d.empresas) {
          e.percent = ((e.entregas*100)/d.entregas).toFixed(2);
        }

        for(const [k, q] of Object.entries(d.raios)) {
          if(q == 0)
            d['raio_' + k.replace('+','mais')] = '0 (0%)';
          else
            d['raio_' + k.replace('+','mais')] = q + ' (' + ((q*100)/this.totalizadores_entregadores.totais[k]).toFixed(2) + '%)';
        }

      }


    },

  },

  methods: {

    async filtrar() {

      let data_inicial;
      if(this.periodo != null && this.periodo[0] != null) {
        data_inicial = moment(this.periodo[0]).tz("America/Sao_Paulo").format("YYYY-MM-DD");
      } else {
          
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "A data inicial precisa ser informada!",
          life: 5000,
        });

        return;
      }

      if(this.$root.logisticaAtual == null) {
          
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "A Empresa de Logística precisa ser selecionada!",
          life: 5000,
        });

        return;
      }

      let data_final;
      if(this.periodo[1] != null) {
        data_final = moment(this.periodo[1]).tz("America/Sao_Paulo").format("YYYY-MM-DD");
      } else {
        data_final = data_inicial;
      }

      this.loading = true;

      const query = "?data-inicial=" + data_inicial + "&data-final=" + data_final;

      this.$api.setBearerToken(Buffer.from(this.$root.logisticaAtual.id).toString('base64'));

      const response = await this.$api.get('/totalizadores_json' + query);
      const data = response.data;

      if(data.success) {

        this.dados_empresas = data.data.empresas;
        this.dados_entregadores = data.data.entregadores;

      } else {

        console.log(data);

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: data.error.message,
          life: 5000,
        });

      }

      this.loading = false;
        
    },

  },
  
};
</script>

<style scoped>

  :deep(.col-entregador) {
    text-transform: capitalize;
    width: 250px;
  }

  .totalizadores {
    width: 12.48%;
    display: inline-block;
  }

  .totalizadores_empresas {
    width: 16.5%;
  }

  .totalizadores span {
    display: block;
  }

</style>